<template>
  <v-card flat>
    <v-card-text
      v-if="error"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ error.message }}
      </v-alert>
    </v-card-text>
    <v-card-text>
      <field-card title="Choose PDF">
        <v-radio-group v-model="from" row>
          <v-radio label="Upload" value="asset" />
          <v-radio label="Link" value="url" />
        </v-radio-group>
        <v-slide-x-transition mode="out-in">
          <asset-manager v-if="from === 'asset'" v-model="value.src" asset-type="document" />
          <v-text-field
            v-else
            v-model="value.src"
            label="Link"
            hint="Attention! The link option will not work if the server does not allow Cross-Origin requests."
            persistent-hint
            outlined
            required
          />
        </v-slide-x-transition>
      </field-card>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MPdfForm',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    error: null,
    from: 'asset'
  })
}
</script>
